@font-face {
  font-family: 'eUkraine-head';
  src: url('../fonts/e-UkraineHead-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'eUkraine-head';
  src: url('../fonts/e-UkraineHead-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'eUkraine';
  src: url('../fonts/e-Ukraine-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'eUkraine';
  src: url('../fonts/e-Ukraine-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'eUkraine';
  src: url('../fonts/e-Ukraine-Medium.otf') format('opentype');
  font-weight: 500;
}
