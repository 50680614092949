.description {
  padding: 70px 0 140px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 50px 0 100px;
  }

  &__title {
    color: #030102;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'eUkraine-head', sans-serif;
    font-weight: 500;

    @media (max-width: 560px) {
      font-size: 24px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      gap: 50px;
    }
  }

  &__left {
    width: 100%;
    max-width: 560px;

    @media (max-width: 960px) {
      max-width: none;
    }
  }

  &__right {
    width: 100%;
    min-width: 600px;
    max-width: 600px;
    padding: 36px 40px;
    border-radius: 32px;
    background: rgba(3, 1, 2, 0.04);
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    @media (max-width: 1100px) {
      min-width: auto;
    }

    @media (max-width: 768px) {
      min-width: auto;
      max-width: none;
    }

    @media (max-width: 560px) {
      padding: 32px 24px;
    }
  }

  &__switcher {
    border-radius: 32px;
    background: rgba(3, 1, 2, 0.04);
    display: inline-flex;
    align-items: center;
    padding: 2px;
    font-size: 16px;

    div {
      padding: 12px 16px;
      border-radius: 30px;
      height: 48px;
      line-height: 24px;
      color: #121212;
      cursor: pointer;
      white-space: nowrap;

      &.active {
        background: #121212;
        color: #fff;
      }
    }

    @media (max-width: 560px) {
      font-size: 14px;

      div {
        height: 40px;
        padding: 8px 12px;
      }
    }

    @media (max-width: 380px) {
      font-size: 13px;

      div {
        height: 40px;
        padding: 8px 12px;
      }
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: 560px) {
      font-size: 14px;
    }
  }

  &__list {
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 18px;

    @media (max-width: 380px) {
      font-size: 16px;
    }
  }

  &__item-icon {
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
    }
  }
}