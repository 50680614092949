.auditory {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__banner {
    width: 100%;
    max-height: 443px;
    min-height: 443px;
    height: 100%;
    border-radius: 32px;
    background: #F8F2F7;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
  }
  &__images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &__img {
    position: absolute;
    z-index: 2;

    &--1 {
      top: 43px;
      left: 83px;

      @media (max-width: 560px) {
        max-width: 46px;
        top: 20px;
        left: 20px;
      }
    }
    &--2 {
      left: 52px;
      bottom: 117px;

      @media (max-width: 560px) {
        max-width: 36px;
        left: 30px;
        bottom: 37px;
      }
    }
    &--3 {
      left: 310px;
      bottom: 38px;

      @media (max-width: 560px) {
        max-width: 36px;
        left: unset;
        right: 18px;
        bottom: 52px;
      }
    }
    &--4 {
      right: 388px;
      bottom: 90px;

      @media (max-width: 560px) {
        max-width: 36px;
        right: 23px;
        top: 28px;
        bottom: unset;
      }
    }
    &--5 {
      top: 26px;
      right: 242px;

      @media (max-width: 560px) {
        display: none;
      }
    }
    &--6 {
      top: 130px;
      right: 45px;

      @media (max-width: 560px) {
        max-width: 51px;
        top: unset;
        bottom: 7px;
        right: 140px;
      }
    }
    &--7 {
      right: 120px;
      bottom: 37px;

      @media (max-width: 560px) {
        display: none;
      }
    }
  }
  &__content {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
  }
  &__title {
    color: #000;
    font-family: 'eUkraine-head', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 24px;

    @media (max-width: 560px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 8px;
    }
  }
  &__text {
    color: rgba(3, 1, 2, 0.64);
    font-family: 'eUkraine', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 48px;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }

  .button-main {
    width: 100%;
    max-width: 200px;
  }
}