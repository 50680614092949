.faq-accordion {
  &__item {
    width: 100%;

    border-bottom: 1px solid #D4D4D4;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.active {
      .faq-accordion {
        &__item-content {
          display: block;
        }

        &__toggle-icon {
          transform: rotate(45deg);
        }
      }
    }
  }

  &__item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    color: #000;
    padding: 16px 16px 32px;
    gap: 24px;

    @media (max-width: 560px) {
      font-size: 16px;
    }

    @media (max-width: 375px) {
      font-size: 15px;
    }
  }
  &__item-content {
    color: rgba(3, 1, 2, 0.64);
    font-family: 'eUkraine', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    overflow: hidden;
    padding: 0 16px 32px;
    display: none;

    @media (max-width: 560px) {
      font-size: 12px;
    }
  }
}