.benefits {
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 35px 0;
  }

  &__item {
    width: 100%;
    max-height: 270px;
    height: 250px;
    padding: 24px;
    border-radius: 16px;
    background: #F8F8F8;
    flex-grow: 1;

    @media (max-width: 768px) {
      max-width: 700px !important;
      height: 250px !important;
      margin-right: 16px;
    }
    @media (max-width: 560px) {
      max-width: 300px !important;
      height: 290px;
      padding: 12px;
    }

    &-icon {
      width: 60px;
      height: 60px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100px;
      background: #FFF;
      margin-bottom: 32px;

      @media (max-width: 560px) {
        width: 50px;
        height: 50px;
        margin-bottom: 4px;
      }

      img {
        max-width: 24px;
        height: auto;
      }
    }

    &-title {
      margin-bottom: 12px;

      @media (max-width: 560px) {
        margin-bottom: 4px;
      }
    }
  }
}