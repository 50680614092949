.burger-menu {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: rgba(3, 1, 2, 0.04);

  border: none;
  outline: none;
}