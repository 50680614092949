.burger-menu-overlay {
  position: fixed;
  top: 61px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 40px 16px 80px 16px;

  background: #fff;

  overflow: hidden;

  width: 100%;
  height: 100%;

  .button-main {
    width: 100%;
    max-width: 100%;
    margin-top: 16px;
  }
}