.ready-partners {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__banner {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 24px;
    min-height: 343px;
    max-height: 343px;
    overflow: hidden;

    img {
      border-radius: 24px;
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 560px) {
      min-height: 443px;
      max-height: 443px;

      img {
        max-width: unset;
        height: 443px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    max-width: 630px;
    margin: 0 auto;

    @media (max-width: 560px) {
      width: calc(100% - 40px);
    }
  }

  &__title {
    color: #fff;
    font-family: 'eUkraine-head', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;

    margin-bottom: 8px;

    @media (max-width: 560px) {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }
  }

  &__text {
    color: #D4D4D4;
    font-family: 'eUkraine', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 24px;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 24px;
    }
  }

  &__form {
    width: 100%;
  }

  &__input-wrapper {
    width: 100%;
    position: relative;
  }
  &__input {
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    border-radius: 12px;
    background: #F5F6F7;
    padding: 8px 8px 8px 16px;

    color: #000;
    font-family: 'eUkraine', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    &::placeholder {
      color: rgba(22, 27, 37, 0.50);
      font-family: 'eUkraine', sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }
  &__input-button {
    width: 36px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border-radius: 8px;
    background: #8F257D;
    border: none;

    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    z-index: 1;

    img {
      max-width: 16px;
    }
  }
}