@import 'components/typography.scss';
@import 'components/helpers.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'eUkraine', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-width: 320px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto;
}

@keyframes rotate {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%   {
    box-shadow: 0 0 0 0 rgba(210, 168, 203, 0.5);
  }
  50% {
    box-shadow: 0 0 0 7px rgba(210, 168, 203, 0.5);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(210, 168, 203, 0);
  }
}

.button-main {
  font-family: 'eUkraine-head', sans-serif;
  background: #8F257D;
  border-radius: 12px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 20px;
  max-width: 140px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 56px;

  &.custom-height {
    height: 48px;
    padding: 16px;
  }

  @media(max-width: 940px) {
    padding: 16px;
    height: 48px;
  }

  &:hover {
    background: #7e216d;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    background: #651b58;
  }

  &--success {
    background: #000;
    cursor: default;
    pointer-events: none;
  }

  &__loader {
    width: 18px;
    height: 18px;
    animation: rotate 1s linear infinite;
  }
}

.button-secondary {
  font-family: 'eUkraine', sans-serif;
  border-radius: 12px;
  border: 2px solid #8F257D;
  color: #8F257D;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  padding: 16px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 56px;

  &:hover {
    background: #8F257D;
    color: #ffffff;
    border: none;
  }
}

.nav {
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  padding-left: 40px;

  @media (max-width: 600px) {
    padding-left: 0;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__link {
    border: none;
    background: none;
    padding: 0 8px;
    color: #000;
    font-family: 'eUkraine', sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    font-weight: 500;

    &.lg {
      font-size: 20px;
      line-height: 32px;
    }

    &.active {
      color: #8F257D;
    }

    &-arrow {
      transition: transform linear 0.3s;

      &.active {
        transform: rotate(-180deg);
        > path {
          stroke: #8F257D;
        }
      }
    }
  }

  &__inner {
    padding: 0 24px;

    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.4s ease-in;

    &.active {
      opacity: 1;
      @media (max-height: 669px) {
        overflow-y: scroll;
        max-height: 320px;
      }
    }
  }
}

.full-width {
  width: 100%;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}
