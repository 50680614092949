.not-found {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: #f7f7f7;
        width: 100%;
    }

    &__card {
        display: flex;
        max-width: 500px;
        width: 100%;
        padding: 40px;
        flex-direction: column;
        align-items: center;
        border-radius: 28px;
        gap: 32px;
        background: #FFF;
        text-align: center;

      @media (max-width: 500px) {
        padding: 40px 0;
        margin: 0 12px;

      }
    }

    &__image {
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 310px;
    }

    &__text {
       color: #333;
       font-size: 16px;
       font-style: normal;
       font-weight: 300;
       line-height: 24px;
       margin-bottom: 16px;

      @media (max-width: 500px) {
        padding: 0 30px;
        font-size: 14px;
      }

      }
  &__button {
    padding: 0 15px;
    font-size: 1rem;
    font-weight: bold;
    background-color: rgba(143, 37, 125, 0.1);
    border-radius: 12px;
    color: #8F257D;
    height: 48px;
    border: none;
    cursor: pointer;

    &:hover {
      background: rgba(143, 37, 125, 0.15)    }
  }
}
