.faq-partners {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__title {
    color: #000;
    text-align: center;
    font-family: 'eUkraine-head', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 40px;

    @media (max-width: 560px) {
      text-align: left;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }

  &__faq-wrapper {
    max-width: 780px;
    width: 100%;
    margin: 0 auto;
  }
}