.advantages-partners {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__title {
    color: #000;
    font-family: 'eUkraine-head', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 40px;

    @media (max-width: 560px) {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 40px;
  }
  &__item {
    padding: 24px;
    border-radius: 32px;
    border: 1px solid #E4E4E4;
    width: calc(47% - 12px);

    &:nth-child(2) {
      margin-right: 80px;
    }

    &:nth-child(3) {
      margin-left: 80px;
    }

    @media (max-width: 560px) {
      width: 100%;
      &:nth-child(2) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-left: 0;
      }
    }

    &-icon {
      width: 60px;
      height: 60px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #F8F8F8;
      margin-bottom: 32px;

      @media (max-width: 560px) {
        width: 48px;
        height: 48px;
        margin-bottom: 24px;
      }
    }

    p {
      color: #000;
      font-family: 'eUkraine', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;

      @media (max-width: 560px) {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
}