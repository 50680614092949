.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  visibility: hidden;
  padding: 16px;

  @media (max-height: 720px) {
    align-items: flex-start;
  }

  &.opened {
    visibility: visible;
  }

  &__content {
    width: 100%;
    max-width: 500px;
    background: #fff;
    border-radius: 12px;
    padding: 48px;
    position: relative;
    z-index: 3;

    .title {
      margin-bottom: 32px;

      @media (max-width: 600px) {
        margin-bottom: 24px;
      }
    }

    @media (max-width: 600px) {
      padding: 32px 16px;
    }
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__close {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;

    @media (max-width: 600px) {
      right: 8px;
      top: 8px;
      width: 20px;
    }
  }

  &__text {
    font-size: 20px;

    &--error {
      color: #FF1744;
    }

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
}