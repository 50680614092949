.join-us {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__content {
    border-radius: 32px;
    background: linear-gradient(91deg, #FFF -29.58%, #F8E3F4 66.15%);

    height: 400px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      height: 250px;
    }
  }

  &__content-wrapper {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      margin-top: 32px;
      max-width: 200px;
    }

    @media (max-width: 768px) {
      max-width: 530px;
    }
  }

  &__image {
    height: auto;
    position: absolute;
    z-index: 1;

    &--1 {
      top: -60px;
      left: -40px;
      transform: rotateZ(45deg);

      @media (max-width: 768px) {
        max-width: 135px;
        top: -30px;
        left: -15px;
      }
      @media (max-width: 560px) {
        max-width: 90px;
      }
    }
    &--2 {
      top: -120px;
      right: 20px;

      @media (max-width: 768px) {
        max-width: 135px;
        top: -50px;
        right: -28px;
      }
      @media (max-width: 560px) {
        max-width: 90px;
        top: -40px;
        right: -15px;
      }
    }
    &--3 {
      max-width: 182px;
      bottom: -40px;
      left: 150px;
      transform: rotateZ(-30deg);

      @media (max-width: 768px) {
        max-width: 120px;
        left: 0;
        bottom: -25px;
      }
      @media (max-width: 560px) {
        max-width: 80px;
      }
    }
    &--4 {
      max-width: 182px;
      bottom: -65px;
      right: 180px;

      @media (max-width: 768px) {
        max-width: 120px;
        bottom: -10px;
        right: 0;
      }
      @media (max-width: 560px) {
        max-width: 80px;
      }
    }
  }
}