.intro {
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 35px 0;
  }

  &__content-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    overflow: hidden;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    @media (max-width: 560px) {
      gap: 20px;
    }
  }

  &__content {
    width: 100%;

    a {
      max-width: 190px;

      @media (max-width: 560px) {
        max-width: 100%;
      }
    }

    @media (max-width: 768px) {
      order: 2;
      max-width: 100% !important;
    }
  }

  &__title {
    span {
      color: #8F257D;

      &.animated {
        border-right: 5px solid #8F257D;

        animation:
            typing 2s steps(40, end) infinite,
            blink-caret .75s step-end infinite;
      }
    }
     @media (max-width: 405px) {
         white-space: nowrap;
         overflow: hidden;
         max-width: 343px;

     }
  }

  &__text {
    max-width: 480px;
  }

  &__image-wrapper {
    overflow: hidden;

    @media (max-width: 768px) {
      max-height: 800px;
    }

    @media (max-width: 768px) {
      max-height: 400px;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    outline: none;

    @media (max-width: 768px) {
      order: 1;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #8F257D; }
}