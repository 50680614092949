.title-40px {
  color: #000;
  font-family: 'eUkraine-head', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}

.title-32px {
  color: #000;
  font-family: 'eUkraine', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

.title-24px {
  color: #000;
  font-family: 'eUkraine', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.title-20px {
  color: #000;
  font-family: 'eUkraine', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.title-16px {
  color: #000;
  font-family: 'eUkraine', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}

.text-18px {
  color: rgba(3, 1, 2, 0.64);
  font-variant-numeric: slashed-zero;
  font-family: 'eUkraine', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;

  &.dark {
    color: #000;
  }
}

.text-20px {
  color: #676767;
  font-family: 'eUkraine', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

.text-16px {
  color: rgba(3, 1, 2, 0.64);
  font-family: 'eUkraine', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;

  &.dark {
    color: #000;
  }
}

.text-14px {
  color: rgba(3, 1, 2, 0.64);
  font-variant-numeric: slashed-zero;
  font-family: 'eUkraine', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;

  &.dark {
    color: #000;
  }
}

.text-12px {
  color: rgba(3, 1, 2, 0.64);
  font-variant-numeric: slashed-zero;
  font-family: 'eUkraine', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;

  &.dark {
    color: #000;
  }
}

.fw {
  &-300 {
    font-weight: 300;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
  &-800 {
    font-weight: 800;
  }
  &-900 {
    font-weight: 900;
  }
}