.cooperation {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__header {
    text-align: center;
  }

  &__title {
    color: #000;
    font-family: 'eUkraine-head', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 24px;

    @media (max-width: 560px) {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 16px;
    }
  }

  &__text {
    color: rgba(3, 1, 2, 0.64);
    font-family: 'eUkraine', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 80px;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 40px;
    }
  }

  &__item {
    max-width: 405px;
    max-height: 464px;
    width: 100%;
    height: 100%;
    border-radius: 32px;
    padding: 8px;

    @media (max-width: 560px) {
      max-width: 330px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__slider {
    padding-left: 92px;

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
}