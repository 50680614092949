.cases-text-block {
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 35px 0;
  }

  &__content {
    border-radius: 32px;
    background: #FEFAEB;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: 56px;

    @media (max-width: 560px) {
      padding: 20px;
    }
  }
  &__icon {
    margin-bottom: 24px;
    max-width: 56px;
    width: 100%;
    height: 100%;

    @media (max-width: 560px) {
      margin-bottom: 10px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}