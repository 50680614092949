.public-offer {
  padding: 80px 0;

  @media (max-width: 560px) {
    padding: 40px 0;
  }

  h1 {
    font-family: 'eUkraine-head', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 30px;
    color: #030102;

    @media (max-width: 560px) {
      font-size: 20px;
      margin-bottom: 12px;
    }
  }

  ol {
    counter-reset: item;
    list-style: none;
  }

  li {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 10px;

    &::before {
      content: counters(item, '.') '.';
      counter-increment: item;
      margin-right: 10px;
    }

    .public-offer__list-item-title {
      font-weight: 500;
      font-size: 16px;
    }

    ol {
      margin: 10px 0 20px;
    }
  }

  &__label {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
  }
}