.contact {
  &__content {
    padding: 32px;
    background: #EEEEF4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 840px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      padding: 32px 16px;

      .form {
        max-width: none;
      }
    }

    @media (max-width: 840px) {
      gap: 32px;
    }
  }

  &__logo {
    width: 100%;
    max-width: 140px;
  }
}