.form {
  width: 100%;
  max-width: 545px;

  .button-main {
    @media (max-width: 560px) {
      max-width: none;
      margin-top: 24px;
    }
  }

  &.contact {
    .form {
      &__wrapper {
        background: #fff;
      }

      &__field {
        &::placeholder {
          @media (max-width: 560px) {
            font-size: 11px;
          }

          @media (max-width: 370px) {
            font-size: 10px;
          }
        }
        &::-moz-placeholder {
          @media (max-width: 560px) {
            font-size: 11px;
          }

          @media (max-width: 370px) {
            font-size: 10px;
          }
        }
        &::-ms-input-placeholder {
          @media (max-width: 560px) {
            font-size: 11px;
          }

          @media (max-width: 370px) {
            font-size: 10px;
          }
        }
        &::-webkit-input-placeholder {
          @media (max-width: 560px) {
            font-size: 11px;
          }

          @media (max-width: 370px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    background: #F5F5F5;
    align-items: center;
    border-radius: 16px;
    height: 64px;
    padding: 8px;
    gap: 8px;
    border: 1px solid transparent;
    position: relative;

    @media (max-width: 560px) {
      padding: 0;
      height: 50px;
    }

    &.error {
      border-color: #FF1744;
    }
  }

  &__error {
    position: absolute;
    bottom: -20px;
    color: #FF1744;
    font-size: 12px;
    font-weight: 300;
    left: 16px;
  }

  &__field {
    width: 100%;
    outline: none;
    font-size: 13px;
    position: relative;
    overflow: hidden;
    resize: none;
    font-family: 'eUkraine', sans-serif;
    background: transparent;
    border: none;
    padding: 0 8px;
    height: 100%;
    font-weight: 400;
    color: #000;

    @media (max-width: 560px) {
      padding: 0 16px;
      height: 50px;
      font-size: 11px;
    }

    @media (max-width: 350px) {
      padding: 0 9px;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 300;
      font-size: 13px;

      @media (max-width: 560px) {
        font-size: 12px;
      }

      @media (max-width: 370px) {
        font-size: 11px;
      }
    }
    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 300;
      font-size: 13px;

      @media (max-width: 560px) {
        font-size: 12px;
      }

      @media (max-width: 370px) {
        font-size: 11px;
      }
    }
    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 300;
      font-size: 13px;

      @media (max-width: 560px) {
        font-size: 12px;
      }

      @media (max-width: 370px) {
        font-size: 11px;
      }
    }
    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 300;
      font-size: 13px;

      @media (max-width: 560px) {
        font-size: 12px;
      }

      @media (max-width: 370px) {
        font-size: 11px;
      }
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    &:not(.contact) {
      .button-main {
        max-width: none;
        margin-top: 24px;
      }

      .form__wrapper {
        border-radius: 12px;
      }
    }
  }

  @media (max-width: 560px) {
    &__wrapper {
      border-radius: 12px;
    }
  }
}