.video {
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  &__video {
    width: 100%;
    display: block;
  }

  &__content {
    position: relative;
    border-radius: 32px;
    overflow: hidden;

    @media (max-width: 768px) {
      border-radius: 24px;
    }

    @media (max-width: 560px) {
      border-radius: 16px;
    }
  }

  &__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.5);
    }
  }
}