.cases-templates {
  padding: 70px 0 120px 0;

  @media (max-width: 560px) {
    padding: 40px 0 80px 0;
  }

  &__content-wrapper {
    height: 400px;
    display: flex;
    border-radius: 16px;
    background: linear-gradient(91deg, #FFF -29.58%, #F8E3F4 66.15%), #FFF;
    justify-content: space-between;

    @media (max-width: 768px) {
      height: auto;
      flex-direction: column;
      padding:  0 32px 32px 32px;
      margin: 0 24px;
    }

    @media (max-width: 500px) {
      padding:  0 16px 40px 16px;
      margin: 0;

    }
  }
  &__title {
    line-height: 40px;
  }

  &__text {
    margin-top: 16px;
    max-width: 485px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__form {
    max-width: 565px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 40px;
    padding-right: 40px;

    @media (max-width: 768px) {
     padding-top: 16px;
     padding-right: 0
    }
  }

  &__button {
    max-width: 100%;
    margin-top: 40px;
  }

  &__label {
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;

    @media (max-width: 768px) {
     display: none;
    }
  }

  &__input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #d1b2c4;
    font-size: 16px;
    background-color: #fff;
    margin-top: 16px;
  }

  &__content {
    max-width: 675px;
    min-width: 460px;
    width: 100%;
    height: 100%;

    padding-left: 40px;
    padding-top: 40px;

    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 768px) {
      padding-left: 0;
    }

    @media (max-width: 500px) {
      min-width: unset;
    }

  }

  &__img {
    position: absolute;
    bottom: 85px;
    right: 35px;

    @media (max-width: 1085px) {
      right: 15px;
    }

    @media (max-width: 768px) {
     display: none;
    }
  }

}