.image-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  &:not(:last-child) {
    margin-bottom: 64px;
  }

  @media (max-width: 768px) {
    gap: 40px;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 560px) {
    gap: 20px;
  }

  &__content {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 50%;

    @media (max-width: 768px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  &__absolute-image {
    position: absolute;
    z-index: 2;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-basis: 50%;

    max-width: 582px;
    min-height: 400px;
    max-height: 400px;
    border-radius: 16px;
    background: #F8F2F7;
    position: relative;

    img {
      max-width: 100%;

      @media (max-width: 560px) {
        max-height: 195px;
      }
    }

    @media (max-width: 768px) {
      max-width: 100%;
      flex-basis: 100%;
    }

    @media (max-width: 560px) {
      min-height: 220px;
      max-height: 220px;
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 16px;

    @media (max-width: 560px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px
    }
  }
  &__text {
    color: #3D3D3D;
    font-family: 'eUkraine', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}