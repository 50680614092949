.templates {
  padding: 70px 0 140px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 50px 0 100px;
  }

  &__chip {
    display: inline-block;
    border-radius: 20px;
    border: 2px solid #8F257D;
    background: rgba(143, 37, 125, 0.06);
    padding: 6px 14px;

    margin-bottom: 24px;

    color: #8F257D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: 560px) {
      padding: 2px 8px;
    }
  }

  &__title {
    color: #030102;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'eUkraine-head', sans-serif;
    font-weight: 500;

    @media (max-width: 560px) {
      font-size: 24px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 960px) {
      flex-direction: column;
      gap: 50px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: 560px) {
      font-size: 14px;
    }
  }

  &__left {
    @media (max-width: 768px) {
      order: 2;
    }
  }

  &__right {
    width: 100%;
    min-width: 600px;
    max-width: 600px;
    border-radius: 32px;
    background: rgba(3, 1, 2, 0.04);
    padding: 40px 40px 0 40px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1100px) {
      min-width: auto;
    }

    @media (max-width: 768px) {
      min-width: auto;
      max-width: none;
      order: 1;
      padding: 40px 20px 0 120px;
      justify-content: center;
    }
    @media (max-width: 560px) {
      padding: 20px 20px 0 20px;
    }
  }

  &__img-wrapper {
    position: relative;

    display: flex;
    align-items: flex-end;
  }

  &__template-img {
    max-width: 100%;
    height: auto;
  }

  &__template-options-img {
    position: absolute;
    top: 80px;
    left: -170px;
    z-index: 1;

    @media (max-width: 960px) {
      max-width: 210px;
      width: 100%;
      top: 80px;
      left: -130px;
    }

    @media (max-width: 560px) {
      max-width: 140px;
      width: 100%;
      top: 20px;
      left: -30px;
    }
  }

  .button-main {
    width: 100%;
    max-width: 240px;
    margin-top: 48px;

    @media (max-width: 560px) {
      margin-top: 32px;
      max-width: none;
    }
  }
}