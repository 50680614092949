.reviews {
  padding: 70px 0 140px;

  @media (max-width: 768px) {
    padding: 50px 0 100px;
  }

  &__title {
    color: #030102;
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'eUkraine-head', sans-serif;
    font-weight: 500;

    @media (max-width: 560px) {
      font-size: 24px;
    }
  }

  &__reviews {
    display: flex;
    gap: 22px;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 32px;
    }
  }

  &__review-item {
    width: 100%;
  }

  &__review-item-message {
    border: 1px solid #D9D9D9;
    border-radius: 16px;
    padding: 21px 32px;
    font-size: 32px;
    line-height: 1;
    letter-spacing: 6px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 30px;
      height: 25px;
      border: 1px solid #D9D9D9;
      border-bottom-right-radius: 100px;
      transform: skew(-30deg, 0deg);
      border-top: none;
      bottom: -25px;
      left: 100px;
      position: absolute;
      background: #fff;

      @media (max-width: 560px) {
        width: 25px;
        height: 20px;
        bottom: -21px;
        left: 95px;
      }
    }

    @media (max-width: 768px) {
      padding: 21px 24px;
      max-width: 375px;
    }

    @media (max-width: 560px) {
      max-width: none;
      font-size: 24px;
      padding: 13px 24px;
    }
  }

  &__review-item-title {
    font-size: 20px;
    line-height: 1.2;
    color: rgba(3, 1, 2, 0.64);
    font-weight: 400;
    margin-top: 21px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}