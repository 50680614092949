@for $i from 0 through 200 {
  .pt-#{$i} {
    padding-top: #{$i}px;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }

  .pl-#{$i} {
    padding-left: #{$i}px;
  }

  .pr-#{$i} {
    padding-right: #{$i}px;
  }

  .p-#{$i} {
    padding: #{$i}px;
  }
}

@for $i from 0 through 200 {
  .mt-#{$i} {
    margin-top: #{$i}px;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .ml-#{$i} {
    margin-left: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }

  .m-#{$i} {
    margin: #{$i}px;
  }
}