.cases-header-button {
  background: none;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;

  .active {
    transform: rotate(-180deg);
    padding-right: 4px;
    transition: transform linear 0.3s;
  }
}