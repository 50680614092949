.form-blocks {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

  }

  &__input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #F8F8F8;
    font-size: 16px;
    background-color: #F8F8F8;
    max-width: 466px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
&__input:last-of-type {
  margin-bottom: 16px;
  }

  &__button {
    max-width:max-content;

    @media (max-width: 768px) {
      max-width: 100%;
    }



  }
}