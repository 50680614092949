.how-it-works {
  padding: 70px 0;
  text-align: center;

  @media (max-width: 560px) {
    padding: 35px 0;
    overflow-x: hidden;
  }

  &__content {
    text-align: left;
    padding-top: 90px;

    @media (max-width: 768px) {
      padding-top: 50px;
    }

    @media (max-width: 560px) {
      padding-top: 32px;
    }
  }
}