.header {
  width: 100%;
  position: relative;
  padding: 30px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background: #fff;

  @media (max-width: 768px) {
    padding: 18px 0;
  }

  @media (max-width: 560px) {
    padding: 10px 0;
  }

  &__logo {
    width: 100%;
    max-width: 128px;
    display: block;

    @media (max-width: 560px) {
      max-width: 103px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  .button-main {
    max-width: 100px;
    min-width: 100px;
    height: 48px;
  }

  &__btns {
    display: flex;
    gap: 16px;
  }
}