.rating {
  padding: 70px 0;
  text-align: center;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    padding-top: 90px;

    @media (max-width: 768px) {
      padding-top: 50px;
      row-gap: 50px;
    }

    @media (max-width: 560px) {
      padding-top: 32px;
      gap: 50px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    flex-basis: 33.33333%;

    @media (max-width: 960px) {
      flex-basis: 50%;
    }

    @media (max-width: 560px) {
      flex-basis: 100%;
    }

    &-content {
      padding-top: 32px;

      max-width: 250px;
      width: 100%;
      margin: 0 auto;
    }
  }
}