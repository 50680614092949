.templates-advantages {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__wrapper {
    text-align: center;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    padding-top: 56px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  &__left {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }

    img {
      max-width: 100%;
      height: auto;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  &__right {
    width: 50%;
    text-align: left;

    @media (max-width: 768px) {
      width: 100%;
    }

    a {
      max-width: 320px;

      @media (max-width: 560px) {
        max-width: 100%;
      }
    }
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}