.cases-menu-overlay {
  position: fixed;
  top: 101px;
  right: 0;
  left: 0;
  z-index: 999;

  max-height: 300px;
  height: 100%;

  padding: 32px 0;
  opacity: 0;

  background: #fff;
  transform: translateY(-400px);

  transition: transform 0.4s ease-out, opacity 0.4s ease-out;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }

  &__overlay {
    position: fixed;
    top: -9999px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 998;

    opacity: 0;

    width: 100%;
    height: 100vh;

    background: rgba(39, 39, 39, 0.60);


    &.active {
      opacity: 1;
      top: 101px;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 440px);
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    width: 100%;
    //max-width: 440px;
    //min-width: 440px;
    max-height: 104px;
    height: 100%;

    padding: 16px;

    border-radius: 12px;
    text-decoration: none;

    &:hover {
      @media (min-width: 560px) {
        background: #F8F2F7;
      }

      .cases-menu-overlay__item-arrow {
        opacity: 1;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 12px;
    }

    &-arrow {
      opacity: 0;
    }
  }
}