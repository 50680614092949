.advantages {
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  &__title {
    color: #030102;
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'eUkraine-head', sans-serif;
    font-weight: 500;

    @media (max-width: 560px) {
      font-size: 24px;
    }
  }

  &__items {
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    padding: 24px;
    border-radius: 8px;
    background: #F5F6F7;
    width: calc(30% - 12px);

    p {
      margin: 0;
      font-weight: 400;
      font-size: 18px;
      color: #030102;

      @media (max-width: 560px) {
        font-size: 16px;
      }

      @media (max-width: 374px) {
        font-size: 14px;
      }
    }

    &:nth-child(3) {
      margin-right: 80px;
    }

    &:nth-child(4) {
      margin-left: 80px;
    }

    @media (max-width: 960px) {
      max-width: 370px;
      min-width: 370px;
      height: 196px;
      margin-right: 16px;
    }

    @media (max-width: 560px) {
      max-width: 300px;
      min-width: 300px;
      height: 183px;
    }

    @media (max-width: 374px) {
      max-width: 240px;
      min-width: 240px;
      height: 190px;
    }
  }

  &__item-icon {
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }

    @media (max-width: 560px) {
      width: 50px;
      height: 50px;
      margin-bottom: 24px;

      img {
        height: 30px;
      }
    }
  }
}