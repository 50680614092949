.banner {
  padding: 70px 0;

  @media (max-width: 560px) {
    padding: 35px 0;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    min-height: 443px;
    max-height: 443px;
    position: relative;
    border-radius: 24px;
    overflow: hidden;

    img {
      border-radius: 24px;
      max-width: 100%;
      height: auto;

      @media (max-width: 560px) {
        max-width: unset;
        height: 100%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @media (max-width: 560px) {
      width: calc(100% - 40px);
    }
  }

  &__title {
    color: #fff;
    font-family: 'eUkraine-head', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;

    margin-bottom: 8px;

    @media (max-width: 560px) {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }
  }
  &__text {
    color: #E4E4E4;
    font-family: 'eUkraine', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 32px;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 24px;
    }
  }
  .button-main {
    width: 100%;
    max-width: 200px;

    @media (max-width: 560px) {
      max-width: none;
    }
  }
}