.about {
  padding: 100px 0 70px;

  @media (max-width: 768px) {
    padding: 120px 0 50px;
  }

  @media (max-width: 560px) {
    padding: 60px 0 50px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    overflow: hidden;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    @media (max-width: 560px) {
      gap: 20px;

      &--partners {
        gap: 40px;
      }
    }
  }

  &__left {
    width: 100%;
    max-width: 520px;

    @media (max-width: 560px) {
      max-width: none;
    }
  }

  &__title {
    font-family: 'eUkraine-head', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 32px;
    color: #030102;

    span {
      color: #8F257D;
    }

    @media (max-width: 1024px) {
      font-size: 32px;
    }

    @media (max-width: 768px) {
      font-size: 40px;
    }

    @media (max-width: 560px) {
      font-size: 24px;
      margin-bottom: 32px;

      &--partners {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
  }

  &__list {
    padding: 0;
    list-style: none;
    margin-bottom: 48px;
    max-width: 490px;

    @media (max-width: 560px) {
      margin-bottom: 32px;
    }

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
      font-weight: 300;
      color: #5E5D5D;
      line-height: 1.2;
      font-size: 18px;

      @media (max-width: 768px) {
        font-size: 18px;
      }


      @media (max-width: 560px) {
        margin-bottom: 12px;
        font-size: 14px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    font-weight: 300;
    color: #5E5D5D;
    line-height: 1.2;
    font-size: 18px;
    padding-bottom: 48px;

    @media (max-width: 768px) {
      font-size: 18px;
      padding-bottom: 40px;
    }

    @media (max-width: 560px) {
      font-size: 14px;
      padding-bottom: 32px;
    }
  }

  .button-main {
    width: 100%;
    max-width: 200px;

    @media (max-width: 560px) {
      max-width: none;
    }
  }

  &__list-num {
    max-height: 48px;
    min-height: 48px;
    max-width: 48px;
    min-width: 48px;
    border-radius: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(3, 1, 2, 0.04);

    img {
      max-width: 24px;
      width: 100%;
    }
  }

  &__right {
    width: 100%;
  }

  &__phone-wrapper {
    width: 327px;
    position: relative;
    margin: 0 auto;

    img {
      width: 100%;
    }

    @media (max-width: 768px) {
      width: 312px;
    }

    @media (max-width: 350px) {
      width: 290px;
    }
  }

  &__dialog {
    position: absolute;
    overflow: hidden;
    width: 290px;
    height: 479px;
    top: 94px;
    left: 19px;
    z-index: 1;

    @media (max-width: 768px) {
      width: 277px;
      top: 90px;
      height: 457px;
      left: 18px;
    }

    @media (max-width: 350px) {
      width: 257px;
      left: 17px;
      top: 85px;
      height: 424px;
    }

    img {
      width: 100%;
      position: relative;
      display: block;
    }

    &--clicked {
      overflow: hidden auto;
    }
  }

  &__phone-btn {
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #8F257D;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 104px;
    right: 30px;
    z-index: 2;
    cursor: pointer;
    transition: .3s;
    animation: pulse linear 1.5s infinite;

    &:hover {
      background: #7e216d;
    }

    img {
      width: auto;
    }
  }

  &__image-wrapper {
    width: 100%;
    position: relative;
  }

  &__screen {
    width: 100%;
    max-width: 310px;
    margin: 0 auto;
    display: block;
    z-index: 2;

    @media (max-width: 480px) {
      &--partners {
        max-width: 150px;
      }
    }
  }

  &__msg-1 {
    width: 100%;
    max-width: 200px;
    position: absolute;
    z-index: 3;
    left: 50px;
    bottom: 90px;

    @media (max-width: 480px) {
      max-width: 150px;
      bottom: 75px;
      left: -85px;

      &--partners {
        max-width: 120px;
        left: 30px;
      }
    }
  }

  &__msg-2 {
    width: 100%;
    max-width: 190px;
    position: absolute;
    z-index: -1;
    right: 40px;
    top: 40px;

    @media (max-width: 480px) {
      max-width: 130px;

      &--partners {
        max-width: 120px;
        right: 20px;
      }
    }
  }
}