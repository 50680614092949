.socials {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 600px) {
    gap: 12px;
  }

  &__button {
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 12px;
    background: #0301020A;
  }
}