.web {
  padding: 70px 0 140px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 50px 0 100px;
  }

  &__title {
    color: #030102;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'eUkraine-head', sans-serif;
    font-weight: 500;

    @media (max-width: 560px) {
      font-size: 24px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 960px) {
      flex-direction: column;
      gap: 50px;
    }
  }

  &__left {
    position: relative;
    z-index: 1;
    width: 100%;
    min-width: 600px;
    max-width: 600px;

    @media (max-width: 1100px) and (min-width: 960px) {
      min-width: 450px;
      max-width: 450px;
    }

    @media (max-width: 960px) {
      min-width: auto;
    }
  }

  &__screen {
    width: 100%;
    max-width: 594px;
    margin: 0 auto;
    display: block;

    @media (max-width: 700px) {
      max-width: 92%;
    }
  }

  &__msg-1 {
    width: 100%;
    max-width: 354px;
    position: absolute;
    z-index: 2;
    left: -40px;
    bottom: 30px;

    @media (max-width: 1100px) and (min-width: 960px) {
      max-width: 280px;
      bottom: 25px;
      left: -30px;
    }

    @media (max-width: 700px) {
      left: -35px;
      max-width: 56%;
    }

    @media (max-width: 600px) {
      left: -15px;
    }

    @media (max-width: 400px) {
      bottom: 10px;
    }
  }

  &__msg-2 {
    width: 100%;
    max-width: 354px;
    position: absolute;
    z-index: 2;
    right: -50px;
    bottom: -15px;

    @media (max-width: 1100px) and (min-width: 960px) {
      max-width: 280px;
      bottom: -20px;
      right: -40px;
    }

    @media (max-width: 700px) {
      right: -35px;
      max-width: 56%;
    }

    @media (max-width: 600px) {
      right: -15px;
    }
  }

  &__right {
    width: 100%;
    max-width: 560px;

    @media (max-width: 960px) {
      max-width: none;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: 560px) {
      font-size: 14px;
    }
  }

  .button-main {
    width: 100%;
    max-width: 200px;
    margin-top: 48px;

    @media (max-width: 560px) {
      margin-top: 32px;
      max-width: none;
    }
  }
}